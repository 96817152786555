<template>
  <div id="newsStoryContent">
    <p id="Text1"><br>
      <!-- pic9D011B8B95CC9C52EE01F1A1F4F2CCAC.jpg -->
      <img style="width: 150px; height: 170px; margin-right: 10px; float: left;"
           class="rounded" src="@/assets/news-network/a-fan-centric-comic-con.jpg">
      It’s been a little over a week since we took our Comic-Con fans by storm. The excitement
      was epic and no need to say we’re still taking it all in.
      <br><br>
      During a full 4 days, the demo stations were constantly swarmed by ecstatic kids and
      parents who couldn’t get enough of demoing the game. And a couple of our most devoted
      fans kept coming back for more. Their passion for LEGO Universe was a giant booster and
      watching them demo the game to other kids was simply awesome.
      <br><br>
      Comic-Con VIP pre-order packages were carried away by eager hands while lucky smaller ones
      took home limited Beta keys. Stacks of cool wall posters signed by Mike Rayhawk, the mind
      behind BrikWars, took up room in tons of ‘Comic-Con-freebie-bags’.
      <br><br>
      <!-- pic33920D9BF37D337BA07CD2C100FCF208.jpg -->
      <img style="width: 440px; height: 265px;"
           class="rounded" src="@/assets/news-network/a-fan-centric-comic-con-2.jpg">
      <br>
      <i><font size="2">Mike Rayhawk</font></i>
      <br><br>
      On Sunday, roughly 500 kids and their parents enjoyed the LEGO Universe panel presentation
      and got to meet with a handful of our enthusiastic creatives behind the game. The
      presentation had a bit of everything from humor filled battle scenes and impressive
      in-game building demonstrations to action packed game trailers, leaving especially the
      little ones glued to the screen. An ongoing pet challenge in the Creation Lab inspired
      the team to pick and bring to life some of the awesome fan created critters. Check out
      the chosen critters below!
      <br><br>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/75b0f751-4b0a-4e9c-afe4-ff6f57a56787"
                   class="rounded" style="margin-right: 10px;">
         <!-- pic429269F145A7D593DEE0F13AA235B98A.jpg -->
         <img style="width: 220px; height: 165px;" alt="Hoppy the Frog" src="@/assets/news-network/creation-lab-hoppy-the-frog.jpg">
      </router-link>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/d1dfd4b0-72bc-464a-9164-7d8ee86dbc21"
                   class="rounded">
        <!-- pic36D91293450DF1FAA54F1E42E15E724C.jpg -->
        <img style="width: 220px; height: 117px;" alt="Snail" src="@/assets/news-network/creation-lab-snail.jpg">
      </router-link>
      <br>
      <i style="width: 220px; display: inline-block; margin-right: 10px;">
        <router-link class="rounded" to="/community/creationlab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/75b0f751-4b0a-4e9c-afe4-ff6f57a56787">
          <font size="2">Hoppy the Frog</font>
        </router-link>
        <font size="2">, By parkernj01</font>
      </i>
      <i>
        <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/cf9e6e4a-601b-4a2c-a879-01ebfb6b2e6d">
          <font size="2">Snail</font>
        </router-link>
        <font size="2">, By kylerkuy</font>
      </i>
      <br><br>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/cf9e6e4a-601b-4a2c-a879-01ebfb6b2e6d"
                   class="rounded" style="margin-right: 10px;">
        <!-- pic6DC0652C98F6802FF3044820E0E7907F.jpg -->
        <img style="width: 220px; height: 165px;" alt="Big pup" src="@/assets/news-network/creation-lab-big-pup.jpg">
      </router-link>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/58a0f3b8-6cf5-4caa-8658-fc4ca708360a"
                   class="rounded">
        <!-- pic774347F24D6A8AE16D7CB402ADAD2E29.jpg -->
        <img style="width: 220px; height: 165px;" alt="Battle Elephant" src="@/assets/news-network/creation-lab-battle-elephant.jpg">
      </router-link>
      <br>
      <i style="width: 220px; display: inline-block; margin-right: 10px;">
        <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/cf9e6e4a-601b-4a2c-a879-01ebfb6b2e6d">
          <font size="2">Big pup</font>
        </router-link>
        <font size="2">, By kt6749</font>
      </i>
      <i>
        <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/c58a0f3b8-6cf5-4caa-8658-fc4ca708360a">
          <font size="2">Battle Elephant</font>
        </router-link>
        <font size="2">, By jakelax10</font>
      </i>
      <br><br>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/31ebd753-537f-4636-872a-7a09066758a5"
                   class="rounded" style="margin-right: 10px;">
        <!-- pic099648D3AE47B1D6533DE61833631087.jpg -->
        <img style="width: 220px; height: 129px;" alt="Blue Frog" src="@/assets/news-network/creation-lab-blue-frog-by-mfrog5.jpg">
      </router-link>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/a18ede62-975e-4b3b-9c43-0770efc99cf9"
                   class="rounded">
        <!-- picB6C25ECA459E9571539FA9B8CB276E67.jpg -->
        <img alt="Leaellynasaura pet (2)" style="width: 220px; height: 149px;" src="@/assets/news-network/creation-lab-leaellynasaura-pet.jpg">
      </router-link>
      <br>
      <i class="caption">
        <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/31ebd753-537f-4636-872a-7a09066758a5">
          <font size="2">Blue Frog</font>
        </router-link>
        <font size="2">, By MFROG5</font>
      </i>
      <i class="caption">
        <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/a18ede62-975e-4b3b-9c43-0770efc99cf9">
          <font size="2">Leaellynasaura pet (2)</font>
        </router-link>
        <font size="2">, By NcR50</font>
      </i>
      <br><br>
      Overall San Diego Comic-Con was a fun and exciting time, and LEGO Universe was a big
      favorite at the LEGO booth and throughout the gaming section of the show. We met tons
      of creative cosplayers (costumed attendees) and who knows, maybe next year we’ll be back
      to find that everyone’s dressed up as heroic LEGO Universe Minifigures? How cool would
      that be!
      <br><br>
    </p>
    <p align="center">
      <!-- picEA3EDC791A86B24B6434A8ED3780C25D.jpg -->
      <img class="rounded" style="width: 175px; height: 233px;" src="@/assets/news-network/a-fan-centric-comic-con-3.jpg">
    </p>
    <p></p>
  </div>
</template>

<style scoped lang="scss">
  .caption {
    display: inline-block;
    width: 220px;
    margin-right: 10px;
  }
</style>
